import Typography, {
  type TypographyColor,
  type TypographyVariant,
} from '#components/shared/ui/Typography';
import analytics from '#utils/analytics.ts';
import type { AnalyticsEventPrimaryOptions } from '#types/Analytics.ts';

type Props = {
  href: string;
  target?: string;
  variant: TypographyVariant;
  color: TypographyColor;
  text: string;
  event: AnalyticsEventPrimaryOptions;
};

const LinkWithAnalytics = ({ event, text, ...props }: Props) => {
  return (
    <Typography {...props} component="a" onClick={() => analytics.event(event)}>
      {text}
    </Typography>
  );
};

export default LinkWithAnalytics;
